<template>
    <div>
        <vx-card class="mb-10">
            

            <vs-button v-if="this.selected.length > 0" @click="activeDeleteDialog()" color="transparent" text-color="gray" class="float-right">
                <feather-icon icon="TrashIcon"></feather-icon>
            </vs-button>

            <vs-button v-else @click="refreshStoresList()" color="transparent" text-color="gray" class="float-right">
                <feather-icon icon="RefreshCwIcon" :class="{ 'animate-spin': refreshShops }"></feather-icon>
            </vs-button>

            <h2 class="text-info mb-10">{{ this.$t('Tiendas') }}</h2>

            <div class="flex justify-end w-full">
                <!-- eslint-disable -->
                <vs-select :label="this.$t('items por página')" v-model="maxItems" class="w-32">
                    <vs-select-item :key="index" :value="item" :text="item" v-for="item,index in maxItemsVals" />
                </vs-select>
            </div>

            <vs-table search :max-items="maxItems" pagination v-model="selected" :data="stores" :noDataText="this.$t('Datos no disponibles') ">
                <template slot="thead">
                    <vs-th class="sm:hidden lg:block">{{ this.$t('Código') }}</vs-th>
                    <vs-th>{{ this.$t('Nombre') }}</vs-th>
                    <vs-th>{{ this.$t('Dirección') }}</vs-th>
                    <vs-th class="sm:hidden">{{ this.$t('Ciudad') }}</vs-th>
                    <!-- <vs-th>Cobertura</vs-th> -->
                    <!-- <vs-th> costo de envío </vs-th> -->
                    <vs-th>{{ this.$t('Más Información') }}</vs-th>
                    <vs-th>{{ this.$t('Estado') }}</vs-th>
                    <vs-th class="w-48"></vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">
                        <!-- codigo -->
                        <vs-td :data="data[indextr].code" class="sm:hidden lg:block">
                            {{ data[indextr].code }}
                        </vs-td>

                        <!-- nombre -->
                        <vs-td :data="data[indextr].name">
                            {{ data[indextr].name }}
                        </vs-td>

                        <!-- direccion -->
                        <vs-td :data="data[indextr].address">
                            {{ data[indextr].address }}
                        </vs-td>

                        <!-- ciudad -->
                        <vs-td :data="data[indextr].city" class="sm:hidden">
                            {{ data[indextr].city }}
                        </vs-td>

                        <!-- cobertura -->
                        <!-- <vs-td :data="data[indextr].influence">
                            {{ data[indextr].influence }} Km de Radio
                        </vs-td> -->

                        <!-- costo de envío -->
                        <!-- <vs-td :data="data[indextr].delivery_price">
                            {{ data[indextr].delivery_price | coin }} COP
                        </vs-td> -->

                        <!-- detalles -->
                        <vs-td :data="data[indextr].scheduling">
                                        
                            <div
                                style="background: #dee2e5; color: #6f758b;"
                                class="w-48 p-1 mb-1 rounded-lg text-center"
                                @click.stop="showMoreInfoSidebar( data[indextr].id )"
                            >
                                <feather-icon icon="EyeIcon" class="align-middle" />
                                {{ $t('Ver Detalles') }}
                            </div>

                        </vs-td>

                        <!-- estado -->
                        <vs-td :data="data[indextr].status">
                            <vs-switch color="success" v-model="data[indextr].status" @click.stop="changeStoreStatus( data[indextr].id )"  :disabled="data[indextr].edit" />
                        </vs-td>

                        <!-- acciones -->
                        <vs-td :data="data[indextr].id">
                            <!-- editar -->
                            <vs-button color="transparent" size="small" @click.stop="toggleEdit( data[indextr].id )" v-if="userEditionStore">
                                <img v-if="data[indextr].edit" :src="require(`@/assets/icons/EditBlueIcon.svg`)" width="16px" />
                                <img v-else :src="require(`@/assets/icons/EditGrayIcon.svg`)" width="13px" />
                            </vs-button>

                            <!-- eliminar -->
                            <vs-button color="transparent" size="small" @click="activeDeleteDialog()" v-if="userDeleteStore">
                                <img :src="require(`@/assets/icons/DeleteGrayIcon.svg`)" width="13px" />
                            </vs-button>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>

        <!-- modal eliminar -->
        <vs-prompt
            @accept="deleteStores()"
            @close="closeDeleteDialog()"
            :active.sync="activePrompt"
            :title="this.$t('Eliminar Tiendas')"
            buttons-hidden
        >
            <div class="w-full">
                <vs-button  class="float-right" @click.native="closeDeleteDialog()" color="transparent" text-color="#666">
                    <feather-icon icon='XIcon'  />
                </vs-button>
            </div>
            
            <div class="w-full flex justify-center mb-5">
                <img :src="require(`@/assets/images/elements/Trash.svg`)" alt="Delete Icon" width="128px" />
            </div>
            
            <div class="text-center text-gray-400">
                <h3 v-if="selected.length === stores.length" class="mb-2">{{ this.$t('Eliminar todas las tiendas') }}</h3>
                <h3 v-else-if="selected.length > 1" class="mb-2">{{ this.$t('Eliminar las tiendas seleccionadas') }}</h3>
                <h3 v-else-if="selected.length === 1" class="mb-2">{{ this.$t('Eliminar tienda') }}</h3>

                <p>
                    <span>{{ this.$t('¿Estás seguro que deseas eliminar definitivamente') }}&nbsp;</span>
                    <span v-if="selected.length === stores.length">{{ this.$t('todas las tiendas') }}</span>
                    <span v-else-if="selected.length > 1">{{ this.$t('las tiendas seleccionadas') }}</span>
                    <span v-else-if="selected.length === 1">{{ this.$t('ésta tienda') }}</span>
                    <span>?</span>
                </p>
                <p><vs-button class="mt-5 rounded-full" @click="deleteStores()">{{ this.$t('Eliminar Definitivamente') }}</vs-button></p>
                <p><vs-button  class="mt-2 text-primary" @click.native="closeDeleteDialog()" color="#FFF">{{ this.$t('Cancelar') }}</vs-button></p>
            </div>
        </vs-prompt>

        <!-- sidebar editar -->
        <edit-sidebar :activeSidebar="activeSidebar" :data="store" @closeSidebar="closeSidebar( $event )"></edit-sidebar>
        
        <!-- sidebar mas info de tienda -->
        <sidebar-more-info :sidebar="moreInfoSidebar" :data="store" @closeSidebarInfo="closeSidebarInfo" @editStore="toggleEdit"></sidebar-more-info>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

import editSidebar from './SidebarEditStore.vue';
import SidebarMoreInfo from './SidebarMoreInfo.vue';
import auth from '../../../auth/authService';

export default Vue.extend({

    data() {
        return {
            selected        : [],
            daysToEs        : {
                M  : 'Lunes',
                TU : 'Martes',
                W  : 'Miércoles',
                TH : 'Juéves',
                F  : 'Viernes',
                SA : 'Sábado',
                SU : 'Domingo',
                H  : 'Festivo',
            },
            activePrompt    : false,
            activeSidebar   : false,
            store           : { id: 0, name: '', code: '', influence: '', price: 0,  scheduling: [], cx: 6.097100, cy:  -74.0817500, },
            moreInfoSidebar : false,
            refreshShops    : false,
            auth,
            maxItems : 10,
            maxItemsVals: [5,10,15,20,25,30,35,40,45,50]
        }
    },

    created() {
        this.refreshStoresList();
    },

    methods: {
        dayToEsp ( day ) {
            return this.$t(this.daysToEs[day]);
        },

        toggleCollapse( storeId ) {
            const stores = this.stores.filter( store => store.id === storeId );
            const store = stores[0];
            store.collapsed = !store.collapsed;

            setTimeout(() => {
                this.selected = [];
            }, 10);
        },

        toggleEdit( storeId ) {
            this.moreInfoSidebar = false;

            const stores = this.stores.filter( store => store.id === storeId );
            const store  = stores[0];

            this.store = store;
            this.activeSidebar = true;
        },

        showMoreInfoSidebar( storeId ) {
            const store = this.stores.find( store => store.id === storeId );       

            this.store           = store;
            this.moreInfoSidebar = true;
        },

        closeSidebar( store ) {
            store.edit = !store.edit;
            this.store = { id: 0, name: '', code: '', influence: '', price: 0,  scheduling: [], cx: 6.097100, cy:  -74.0817500, };
            this.activeSidebar = false
        },

        closeSidebarInfo( val ) {
            this.moreInfoSidebar = val;
            this.store           = { name: '', code: '', influence: '', price: 0,  scheduling: [], cx: 6.097100, cy:  -74.0817500, };
        },

        activeDeleteDialog() {
            this.activePrompt = true;
        },
        
        deleteStores() {
            this.selected.forEach( store => {
                this.$store.dispatch( 'stores/deleteStore', store.id );
            });
            
            this.selected = [];
            this.activePrompt = false;
        },
        
        closeDeleteDialog() {
            this.activePrompt = false;
            this.selected = [];

        },

        changeStoreStatus( storeId ) {
            this.$vs.loading();

            const stores = this.stores.filter( store => store.id === storeId );
            const store = stores[0];
            const newStore = {
                id    : store.id,
                status: !store.status,
                // scheduling: Object.values( store.scheduling ).length > 0 ? store.scheduling.list_scheduling : [],
            }

            this.$store.dispatch( 'stores/uploadStore', newStore )
                .then(() => this.$vs.loading.close() );
        },

        refreshStoresList() {
            this.refreshShops = true;
            this.$store.dispatch( 'stores/getAllStores' )
                .then( () => {
                    this.refreshShops = false;
                });
        }

    },
    
    computed: {
        stores() {
            const stores = this.$store.state.stores.stores;

            // Add edit and collapsed attr in stores array
            const storesMapped = stores.map( store => {
                let ob = Object.assign( {}, store );
                ob.edit = false;
                ob.collapsed = true;
                return ob;
            });

            return storesMapped;
        },
        userEditionStore() {
            const isValid = this.auth.isValidModule('MANAGE_SHOPS, UPDATE_SHOPS');
            return isValid;
        },
        userDeleteStore() {
            const isValid = this.auth.isValidModule('MANAGE_SHOPS, DELELE_SHOPS');
            return isValid;
        },
    },

    components: {
        editSidebar,
        SidebarMoreInfo,
    }

})
</script>

<style lang="scss">
.vs-dialog-header {
    display: none !important;
}
.v-select.drop-up.vs--open .vs__dropdown-toggle {
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    border-bottom-color: rgba(60, 60, 60, 0.26);
    z-index: 1000;
  }

  [data-popper-placement='top'] {
    border-radius: 4px 4px 0 0;
    border-top-style: solid;
    border-bottom-style: none;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15)
  }
</style>