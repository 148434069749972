<template>
  <vs-sidebar click-not-close position-right parent="#app" class="add-new-data-sidebar items-no-padding" spacer v-model="localActiveSidebar">
    <div class="mt-6 flex items-center justify-between px-6" slot="header">
      <h4 class="text-white">{{ this.$t('Modificar Tienda') }}</h4>
      <feather-icon icon="XIcon" @click.stop="localActiveSidebar = false" class="cursor-pointer"></feather-icon>
    </div>


    <VuePerfectScrollbar class="scroll-area--data-list-add-new pt-5 pl-5 pr-5">
      <div class="vx-row">
        <div clas="vx-col w-full" style="width: 95%;margin-left: 2%;display: inline-flex;">
          <!-- imagen -->
          <div class="vx-col w-1/3 mt-20 pt-1">
            <label for="category">{{ this.$t('Portada') }} </label>
          </div>
          <div class="vx-col w-2/3 pt-1">
            <input
              type="file"
              name="fields[assetsFieldHandle][]"
              id="assetsFieldHandle"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              ref="file"
              accept="image/png, image/jpeg"
              @change="onChange()"
            />

            <div
              v-if="!image.file"
              class="border-2 border-dashed text-center pt-5 pb-8 mt-10 cursor-pointer"
              @click="clickFileButton()"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <img
                :src="require(`@/assets/icons/UploadFileIcon.svg`)"
                width="30px"
                class="inline-block align-middle"
              /><br /><br />
              <span class="text-gray" style="font-size:small">Suelta tu imagen aquí, ó</span>&nbsp;
              <vs-button color="#daf7fd" text-color="#1abfda" class="rounded-full small"
                >Busca Archivo</vs-button
              >
            </div>

            <div v-if="!image.file" class="vx-row mt-2">
              <div class="vx-col">
                <p class="text-gray" style="font-size:small">Formatos Soportados: JPG, PNG</p>
              </div>

              <div class="vx-col">
                <span class="text-gray float-right" style="font-size:small">Tamaño Máximo: 5Mb</span>
              </div>
            </div>
            <vx-card v-else class="mt-1">
              <div class="vx-row">
                <div class="vx-col w-auto mt-8">
                  <img
                    :src="require(`@/assets/icons/JpgFileIcon.svg`)"
                    width="30px"
                    class="inline-block align-middle"
                  /><br /><br />
                </div>

                <div class="vx-col w-12/12">
                  <feather-icon

                    icon="XIcon"
                    class="float-right cursor-pointer"
                    @click="image.file = null"
                  />
                  <h6>{{ image.file.name }}</h6>
                  <span>Subido {{ image.progress }}%</span>
                  <vs-progress
                    :height="6"
                    :percent="image.progress"
                    :color="image.error ? 'danger' : 'success'"
                  ></vs-progress>
                </div>
              </div>
            </vx-card>
          </div>
        </div>
        
        <!-- nombre -->
        <div class="vx-col w-1/3 mt-5 pt-1">
          <label for="name">{{ this.$t('Nombre') }}</label>
        </div>
        <div class="vx-col w-2/3 mt-5">
          <vs-input v-model="localStore.name" :danger="!!validate.nameError" id="name"/>
          <span v-if="!!validate.nameError" class="text-danger"> {{ validate.nameError }} </span>
        </div>

        <!-- código -->
        <div class="vx-col w-1/3 mt-2 pt-1">
          <label for="code">{{ this.$t('Código') }}</label>
        </div>
        <div class="vx-col w-2/3 mt-2">
          <vs-input :value="localStore.code" id="code" readonly/>
        </div>

        <!-- dirección -->
        <div class="vx-col w-1/3 mt-2 pt-1">
          <label for="address">{{ this.$t('Dirección') }}</label>
        </div>
        <div class="vx-col w-2/3 mt-2">
          <vs-input v-model="localStore.address" :danger="!!validate.addressError" id="address"/>
          <span class="text-danger"> {{ validate.addressError }} </span>
        </div>

        <!-- ciudad -->
        <div class="vx-col w-1/3 mt-2 pt-1">
          <label for="city">{{ this.$t('Ciudad') }}</label>
        </div>
        <div class="vx-col w-2/3 mt-2">
          <v-select
            :options="cities"
            v-model="localStore.city"
            label="name"
            :reduce="option => option.dane"
            class="dropdown-menu-app"
            id="city"
          >
            <template slot="option" slot-scope="option"> {{ option.name }} </template>
            <template slot="selected-option" slot-scope="option"> {{ option.name }} </template>
          </v-select>
        </div>

        <!-- costo de envío -->
        <div class="vx-col w-1/3 mt-2 pt-1">
          <label for="delivery_price"> {{ this.$t('Costo de envío') }} </label>
        </div>
        <div class="vx-col w-2/3 mt-2">
          <div class="mt-1 relative rounded-lg shadow-md">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm"> R$ </span>
            </div>

            <vs-input type="number" v-model="localStore.delivery_price" :danger="!!validate.priceError" id="delivery_price" class="ml-10 block w-5/6 pr-12 border-gray-300 rounded-md"/>
            
            <div class="absolute inset-y-0 right-0 flex items-center">
              <span class=" pr-5"> BRL </span>
            </div>
          </div>
          <span v-if="!!validate.priceError" class="text-danger"> {{ validate.priceError }} </span>
        </div>

        <!-- precioo mínimo de compra -->
        <div class="vx-col w-1/3 mt-5 mb-2 pt-1">
          <label for="minimum_purchase">{{ this.$t('Precio mínimo de compra') }}</label>
        </div>
        <div class="vx-col w-2/3 mt-5">
          <div class="mt-1 relative rounded-lg shadow-md">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm"> R$ </span>
            </div>

            <vs-input
              type="number"
              v-model="localStore.minimum_purchase"
              :danger="!!errs.minimum_purchase"
              id="minimum_purchase"
              class="ml-10 block w-5/6 pr-12 border-gray-300 rounded-md"
            />
            
            <div class="absolute inset-y-0 right-0 flex items-center">
              <span class=" pr-5"> BRL </span>
            </div>
          </div>
          <span v-if="!!errs.minimum_purchase" class="text-danger"> {{ errs.minimum_purchase }} </span>
        </div>

        <!-- telefono -->
        <div class="vx-col w-1/3 mt-2 pt-1">
          <label for="phone">{{ this.$t('Teléfono') }}</label>
        </div>
        <div class="vx-col w-2/3 mt-2">
          <vs-input v-model="localStore.phone" id="phone"/>
        </div>

        <!-- Cobertuta -->
        <div class="vx-col w-1/3 mt-2 pt-1">
          <label for="influence"> <nobr> {{ this.$t('Cobertura') }} </nobr> </label>
        </div>
        <div class="vx-col w-2/3 mt-5">
          <div class="mt-1 relative rounded-lg shadow-md">
            <vs-input type="number" v-model="localStore.influence" id="influence" :danger="!!validate.influenceError" 
              class="ml-0 block w-6/6 pr-18 border-gray-300 rounded-md"/>
            
            <div class="absolute inset-y-0 right-0 flex items-center">
              <span class=" pr-5"> Km(s) </span>
            </div>
          </div>
          <span v-if="!!validate.influenceError" class="text-danger"> {{ validate.influenceError }} </span>
        </div>
        <!-- Categoria de tienda -->
        <div class="vx-col w-1/3 mt-2 pt-1">
            <label for="category">{{ this.$t('Categoría') }} </label>
        </div>
        <div class="vx-col w-2/3 mt-2">
          <v-select
            v-model="localStore.list_type_shops"
            :options="shopCategories"
            label="name"
            multiple
            :reduce="option => option.id"
            class="dropdown-menu-app"
            id="category"
          >
            <template slot="option" slot-scope="option"> {{ option.name }} </template>
            <template slot="selected-option" slot-scope="option"> {{ option.name }} </template>
          </v-select>
        </div>

        <!-- horarios -->
        <div class="vx-col w-full mt-4">
            <label for="category" class="title">{{ this.$t('Horarios') }}</label>
        </div>
        <business-hours
          class="mt-5 mb-10 pl-2 pr-2"
          :selectedTab="selectedTab"
          :fixedHours="fixedHours"
          :specificShedules="specificShedules"
          :holidayHours="holidayHours"
          @selectedTab="selectedTab = $event"
          @fixedHours="fixedHours = $event"
          @specificShedules="specificShedules = $event"
          @holidayHours="holidayHours = $event"
          @hoursErr="hoursErr = $event"
        ></business-hours>
      </div>
    </VuePerfectScrollbar>
            
    <div class=" items-center p-6" slot="footer">
      <vs-button type="border" color="danger" @click="localActiveSidebar = false">{{ this.$t('Cancelar') }}</vs-button>
      <button class="vs-component vs-button float-right bg-primary vs-button-filled" @click="saveImage()">
        {{ this.$t('Guardar') }}
      </button>
    </div>
  </vs-sidebar>
</template>

<script lang="ts">
import Vue                 from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect             from 'vue-select';

import { Validation }      from '../../../plugins/validation.js';
import businessHours       from './BusinessHours.vue';

export default Vue.extend({
  props: {
    activeSidebar: { required: true, type: Boolean, },
    data: { required: true, },
  },

  data() {
    return {
      validate    : new Validation(),
      selectedTab : 0,
      fixedHours  : {
        mf   : { name: 'Lunes a Viernes', hInit: '08:00:00', hEnd: '21:30:00' },
        sasu : { name: 'Sábados y Domingos', hInit: '08:00:00', hEnd: '18:30:00' },
      },
      image: {
        progress: 0,
        error: "",
        file: null,
      },
      validFormats: ["jpg", "jpeg", "png"],
      specificShedules : [
        { name: 'Lunes',     code: 'M',  hInit: '08:00:00', hEnd: '21:00:00' },
        { name: 'Martes',    code: 'TU', hInit: '08:00:00', hEnd: '21:00:00' },
        { name: 'Miércoles', code: 'W',  hInit: '08:00:00', hEnd: '21:00:00' },
        { name: 'Jueves',    code: 'TH', hInit: '08:00:00', hEnd: '21:00:00' },
        { name: 'Viernes',   code: 'F',  hInit: '08:00:00', hEnd: '21:00:00' },
        { name: 'Sábado',    code: 'SA', hInit: '08:00:00', hEnd: '21:00:00' },
        { name: 'Domingo',   code: 'SU', hInit: '08:00:00', hEnd: '21:00:00' },
      ],
      holidayHours     : {
        hInit: '08:00:00',
        hEnd: '13:30:00',
      },
      hoursErr         : false,
      timeout          : null,
      center           : { lat: 4.6097100, lng: -74.0817500, },
      getAddressGeoSvc : false,
      errs : { minimum_purchase : '', },
    }
  },

  watch: {
    'data.id': async function( val ) {
      if( !val || val === 0 ) {
        this.localActiveSidebar = false;
        return;
      }
      
      this.$vs.loading();
      const res = await this.$store.dispatch( 'stores/getStoreById', this.data.id );
      this.$vs.loading.close();
      
      if( res ) this.center = { lat: res.data[0].cy, lng: res.data[0].cx };
      this.mapScheduleHours( res.scheduling );          
    },
    'marker'( val ) {
      clearTimeout( this.timeout );
      this.timeout = setTimeout(() => {
        this.center = val;
        if( this.getAddressGeoSvc ) this.validateCoords();
      }, 2000);  
    },
    'localStore.name'( val ) { this.validate.validateName( val ); },
    'localStore.address' : async function() {
      clearTimeout( this.timeout );
      await this.validateAddress;
      this.timeout = setTimeout(async () => {
      }, 3000);
    },
    'localStore.city' : async function() { await this.validateAddress; },
    'localStore.delivery_price'( val ) { this.validate.validatePrice( val ); },
    'localStore.influence'( val ) { this.validate.validateInfluence( val ); },
    'localStore.minimum_purchase'(val) { this.validateMinPrice( parseFloat( val ) ); },
  },

  created() {
    //this.$store.dispatch( 'location/getAllCities' );
  },

  methods: {
    dragend( coords ) {
      const coordinates = { cy: coords.latLng.lat(), cx: coords.latLng.lng() }
      this.getAddressGeoSvc = true;
      this.$store.commit( 'stores/SET_STORE', {
        ...this.localStore,
        cx: coordinates.cx,
        cy: coordinates.cy,
      });
    },
    async validateCoords() {
      const payload = {
        cx: this.localStore.cx,
        cy: this.localStore.cy,
        geo: 'INVERSO',
      }
      const res = await this.$store.dispatch( 'location/getAddresInfo', payload );
      
      if( res ) {
        this.validateAddress = false;
        this.$store.commit( 'stores/SET_STORE', {
          ...this.localStore,
          address: res.address1,
          city: res.city1,
        });

        return true;
      }
                
      return false;
    },
    async validateAddress() {
      const address        = this.localStore.address;
      const isValidCity    = !!this.localStore.city_dane;

      if( !isValidCity ) {
        this.validate.addressError = 'Seleccione la ubicación en el mapa';
        return false;
      }

      const city = this.cities.find( city => {
        return city.dane == this.localStore.city_dane 
      })
        
      if( !city ) {
        this.validate.addressError = 'Seleccione la ubicación en el mapa';
        return false;
      }
            
      const payload = {
        city: city.name,
        address,
        geo: 'CODER',
      }
            
      const res = await this.$store.dispatch( 'location/getAddresInfo', payload );
            
      if( res ) {
        this.$store.commit( 'stores/SET_STORE', {
          ...this.localStore,
          cx: parseFloat( res.cx ),
          cy: parseFloat( res.cy ),
        });
          
        return true;
          
      } else {
        this.validate.addressError = 'Seleccione la ubicación en el mapa';
        return false;

      }
    },
    onChange() {
      const filename = this.$refs.file.files[0].name;
      const filesize = this.$refs.file.files[0].size;
      const filesizeMb = Math.floor(filesize / 1000000);
      const ext = filename.substr(filename.lastIndexOf(".") + 1).toLowerCase();

      const isValidFormat = this.validFormats.filter(
        (format) => format === ext
      );

      if (filesizeMb > 2) {
        this.$vs.notify({
          title: `La imagen tiene un tamaño de ${filesizeMb} Mb`,
          text: `El límite de tamaño es de 2Mb`,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          time: 7000,
        });
        return;
      }

      if (isValidFormat.length === 0) {
        this.$vs.notify({
          title: "Formato Incorrecto",
          text: "Cargue archivos .jpg ó .png solamente",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return;
      }

      this.image.file = this.$refs.file.files[0];
      // this.$store.dispatch( 'product/loadProductImage', this.image );
    },
    dragover(ev) {
      ev.preventDefault();

      if (!ev.currentTarget.classList.contains("bg-primary")) {
        ev.currentTarget.classList.remove("bg-gray-100");
        ev.currentTarget.classList.add("bg-primary");
      }
    },
    dragleave(ev) {
      ev.currentTarget.classList.remove("bg-primary");
    },
    drop(ev) {
      ev.preventDefault();

      this.$refs.file.files = ev.dataTransfer.files;
      this.onChange();
      ev.currentTarget.classList.remove("bg-primary");
    },
    clickFileButton() {
      this.$refs.file.click();
    },
    saveImage() {
      this.$vs.loading();
      if (this.image){
        this.$store
          .dispatch("firebase/uploadImage", this.image)
          .then((res) => {
            this.$vs.loading.close();
            this.localStore.cover_page = res;
            this.saveData();
          })
          .catch(() => {
            this.$vs.loading.close();
            this.setToastMessage(
              "Ocurrió un error al cargar la imagen. Intente unos minutos más tarde"
            );
          });
      } else {
        this.saveData();
      }
    },
    async saveData() {
      //let isValidAddress = false;
      const isValidName      = this.validate.validateName( this.localStore.name );
      const isValidPrice     = this.validate.validatePrice( this.localStore.delivery_price );
      const isValidInfluence = this.validate.validateInfluence( this.localStore.influence );
      const isValidMinPrice  = this.validateMinPrice( parseFloat( this.localStore.minimum_purchase ) );

      /* if( this.getAddressGeoSvc ) isValidAddress = await this.validateCoords();
      else isValidAddress = await this.validateAddress();
       */      
      if( !isValidName || !isValidPrice || !isValidInfluence || !isValidMinPrice ) {
        this.$vs.notify({
          title: 'Verifique los campos antes de continuar',
          text: '',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          time: 7000,
        });               

        return false;
      }
      
      var schedule = [];

      if( this.selectedTab === 0 ) {
        schedule.push({ 
          end_hour: this.fixedHours.mf.hEnd, 
          init_hour: this.fixedHours.mf.hInit, 
          date_prog: 'M|TU|W|TH|F' });
        schedule.push({ 
          end_hour: this.fixedHours.sasu.hEnd, 
          init_hour: this.fixedHours.sasu.hInit, 
          date_prog: 'SA|SU' });

      } else {
        this.specificShedules.forEach( sch => {
          schedule.push({ end_hour: sch.hEnd, init_hour: sch.hInit, date_prog: sch.code });
        });
      }

      if( this.holidayHours ) schedule.push({ end_hour: this.holidayHours.hEnd, init_hour: this.holidayHours.hInit, date_prog: 'H' });

      const store = {
        ...this.localStore,
        scheduling : schedule,
      }

      this.$vs.loading();
      const res = await this.$store.dispatch( 'stores/uploadStore', store );
      this.$vs.loading.close();

      if( res ) this.localActiveSidebar = false;
    },
    mapScheduleHours( sch=[] ) {
      const scheduling  = sch.length > 0 ? sch : this.localStore.scheduling;
      const schedMapped = scheduling.map( sched => {
        const ob = Object.assign( {}, sched );
        
        ob.hInit = sched.init_hour;
        ob.hEnd  = sched.end_hour;

        return ob;
      });
      const fixedHoursMF = schedMapped.find( sched => sched.code === 'M|TU|W|TH|F' );
      const fixedHoursSS = schedMapped.find( sched => sched.code === 'SA|SU' );
      const holidayHours = schedMapped.find( sched => sched.code === 'H' );
      const mHours      = schedMapped.find( sched => sched.code === 'M' );
      const tuHours     = schedMapped.find( sched => sched.code === 'TU' );
      const wHours      = schedMapped.find( sched => sched.code === 'W' );
      const thHours     = schedMapped.find( sched => sched.code === 'TH' );
      const fHours      = schedMapped.find( sched => sched.code === 'F' );
      const saHours     = schedMapped.find( sched => sched.code === 'SA' );
      const suHours     = schedMapped.find( sched => sched.code === 'SU' );

      // hora de lunes a viernes
      if( fixedHoursMF ) {
          this.selectedTab = 0;
          this.fixedHours.mf = fixedHoursMF;

      } else {
        this.selectedTab = 1;
      }

      // hora sabados y domingos
      if( fixedHoursSS ) {
          this.selectedTab = 0;
          this.fixedHours.sasu = fixedHoursSS;

      } else {
        this.selectedTab = 1;
      }
      // horario festivo
      if( holidayHours ) this.holidayHours = holidayHours;
      // de lunes a viernes fechas especificas
      if( mHours )  this.specificShedules[0] = mHours;
      if( tuHours ) this.specificShedules[1] = tuHours;
      if( wHours )  this.specificShedules[2] = wHours;
      if( thHours ) this.specificShedules[3] = thHours;
      if( fHours )  this.specificShedules[4] = fHours;
      if( saHours ) this.specificShedules[5] = saHours;
      if( suHours ) this.specificShedules[6] = suHours;
            
    },
    validateMinPrice( val=0 ) {
        if( val < 0 ) this.errs.minimum_purchase = 'Ingrese un valor mayor a cero';
        else this.errs.minimum_purchase = '';
        return this.errs.minimum_purchase.length === 0 ? true : false;
        
    }
  },

  computed: {
    localActiveSidebar: {
      get: function() {
        return this.activeSidebar;
      },
      set: function() {
        this.$emit( 'closeSidebar', this.data );
        this.$store.dispatch( 'stores/getAllStores' );
        this.$store.commit( 'stores/CLEAR_STORE_DATA' );
      }
    },
    localStore: {
      get() {
        const store = this.$store;
        const shop = store.state.stores.store;
        let storeProxy = new Proxy( shop, {
          set (obj, key, value) {
            store.commit('stores/SET_STORE', {
              ...obj,
              [key]: value,
            });    
            return true
          }
        });

        return storeProxy;
      },
    },
    marker() { return { lat: this.localStore.cy, lng: this.localStore.cx } },
    cities() { return this.$store.state.location.cities; },
    shopCategories() { return this.$store.state.stores.categories; },
  },

  components: {
    VuePerfectScrollbar,
    businessHours,
    vSelect,
  }

});
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }
    
  ::v-deep .vs-sidebar {
    position: fixed;
    z-index: 52010;
    width: 450px;
    max-width: 90vw;
    .vs-sidebar--header {
      background: #841910;
      color: #FFF;
    }
  }

  .vs-con-input-label{
    width: 100%;
  }
}

.title{
  font-weight: bold;
  font-size: larger;
  color: black;
}

.scroll-area--data-list-add-new { height: 77vh; }

</style>
