<template>
  <vs-sidebar click-not-close position-right parent="#app" class="more-info-sidebar" spacer v-model="localActiveSidebar">
    <div class="mt-6 flex items-center justify-between px-6" slot="header">
      <h4 class="text-white"> {{ this.$t('Información de Tienda') }} {{ store.name }} </h4>
      <feather-icon icon="XIcon" @click.stop="localActiveSidebar = false" class="cursor-pointer"></feather-icon>
    </div>


    <VuePerfectScrollbar class="scroll-area--data-list-add-new pl-5 pr-5">
      <div class="vx-row">
        <!-- mapa -->
        <div class="vx-col w-full">
          <label for="location" class="title">{{ this.$t('Ubicación') }}</label>

          <GmapMap
            :center="location"
            :zoom="15"
            style="height: 30vh"
            id="location"
          >
            <GmapMarker
              :position="location"
              :clickable="false"
              :draggable="false"
              :icon="{
                url: require('@/assets/icons/MarkerIcon.png'),
                size: {width: 40, height: 40, f: 'px', b: 'px'},
                scaledSize: {width: 40, height: 40, f: 'px', b: 'px'},
              }"
            />
          </GmapMap>
        </div>

        <!-- nombre -->
        <div class="vx-col w-1/3 mt-5 pt-1">
          <label for="name">{{ this.$t('Nombre') }}</label>
        </div>
        <div class="vx-col w-2/3 mt-5">
          <div class="bg-grey p-1 rouned rounded-lg border border-solid cursor-text" id="name">
            {{ store.name }}
          </div>
        </div>

        <!-- código -->
        <div class="vx-col w-1/3 mt-2 pt-1">
          <label for="code">{{ this.$t('Código') }}</label>
        </div>
        <div class="vx-col w-2/3 mt-2">
          <div class="bg-grey p-1 rouned rounded-lg border border-solid cursor-text" id="code">
            {{ store.code }}
          </div>
        </div>

        <!-- direccion -->
        <div class="vx-col w-1/3 mt-2 pt-1">
          <label for="address">{{ this.$t('Dirección') }}</label>
        </div>
        <div class="vx-col w-2/3 mt-2">
          <div class="bg-grey p-1 rouned rounded-lg border border-solid cursor-text" id="address">
            {{ store.address }}
          </div>
        </div>

        <!-- ciudad -->
        <div class="vx-col w-1/3 mt-2 pt-1">
          <label for="city">{{ this.$t('Ciudad') }}</label>
        </div>
        <div class="vx-col w-2/3 mt-2">
          <div class="bg-grey p-1 rouned rounded-lg border border-solid cursor-text" id="city">
            {{ store.city }}
          </div>
        </div>

        <!-- costo de envío -->
        <div class="vx-col w-1/3 mt-2 pt-1">
          <label for="delivery_price"> {{ this.$t('Costo de envío') }} </label>
        </div>
        <div class="vx-col w-2/3 mt-2">
          <div class="bg-grey p-1 rouned rounded-lg border border-solid cursor-text" id="delivery_price">
            {{ store.delivery_price | coin }} COP
          </div>
        </div>

        <!-- telefono sí existe -->
        <div v-if="store.phone" class="vx-col w-1/3 mt-2 pt-1">
          <label for="phone">{{ this.$t('Teléfono') }}</label>
        </div>
        <div v-if="store.phone" class="vx-col w-2/3 mt-2">
          <div class="bg-grey p-1 rouned rounded-lg border border-solid cursor-text" id="phone">
            {{ store.phone }}
          </div>
        </div>

        <!-- Cobertuta -->
        <div class="vx-col w-1/3 mt-2 pt-1">
          <label for="influence"> <nobr> {{ this.$t('Cobertura') }} (Km) </nobr> </label>
        </div>
        <div class="vx-col w-2/3 mt-2">
          <div class="bg-grey p-1 rouned rounded-lg border border-solid cursor-text" id="influence">
            {{ store.influence }} Km
          </div>
        </div>

        <!-- Categoria de tienda -->
        <div class="vx-col w-1/3 mt-2 pt-1">
          <label for="category">{{ this.$t('Categoría') }} </label>
        </div>
        <div class="vx-col w-2/3 mt-2">
          <!-- eslint-disable -->
          <vs-chip v-for="type_, i of store.list_type_shops" :key="i" class="bg-info text-white">
            {{ getCategoryName( type_ ) }}
          </vs-chip>
        </div>

        <!-- horarios -->
        <div class="vx-col w-full mt-4">
          <label for="hours" class="title">{{ this.$t('Horarios') }}</label>
        </div>

        <div v-if="store.scheduling.length === 0 " class="vx-col w-full mt-2 text-center">
          <feather-icon icon="AlertCircleIcon" class="align-middle mr-2"/>
          <i>{{ this.$t('No hay horarios disponibles') }}</i>
        </div>

        <div v-else class="vx-col w-full mt-2 text-center">
          <div v-for="( time_, i ) in store.scheduling" v-bind:key="i">
            <div style="background: #dee2e5; color: #6f758b;" class="w-48 p-1 mb-1 rounded text-center inline-block font-bold">{{ $t(time_.name) }}</div>
            
            <span>&nbsp;{{ time_.init_hour | timeWithoutSecond }} {{ $t('a') }} {{ time_.end_hour | timeWithoutSecond }}</span>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>
            
    <div class="items-center p-4" slot="footer">
      <vs-button type="border" color="#666" @click="localActiveSidebar = false">{{ this.$t('Cerrar') }}</vs-button>
      
      <button class="vs-component vs-button vs-button-info vs-button-border mb-2 float-right" @click="$emit( 'editStore', store.id )">
        {{ this.$t('Editar') }}
      </button>
    </div>
  </vs-sidebar>
</template>

<script lang="ts">
import Vue from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default Vue.extend({
  props: {
    sidebar: { required: true, type: Boolean, },
    data: { required: true, },
  },

  methods: {
    getCategoryName( categoryId ) {
      const categorie = this.shopCategories.find( cat => cat.id === categoryId );
      if( categorie ) return categorie.name;
      return '';
    },
  },

  computed: {
    localActiveSidebar: {
        get: function() {
          return this.sidebar;
        },
        set: function( val ) {                
          this.$emit( 'closeSidebarInfo', val );
          this.$store.dispatch( 'stores/getAllStores' );
        }
    },

    store: {
      get: function() {
        return this.data;
      }
    },

    location() {
      return {
        lat : this.store.cy,
        lng : this.store.cx,
      }
    },

    shopCategories() {
      return this.$store.state.stores.categories;
    },
  },

  components: {
    VuePerfectScrollbar,
  }
})
</script>

<style lang="scss" scoped>
.more-info-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }
    
  ::v-deep .vs-sidebar {
    position: fixed;
    z-index: 52010;
    width: 450px;
    max-width: 90vw;
    .vs-sidebar--header {
      background: #841910;
      color: #FFF;
    }
  }
}

.scroll-area--data-list-add-new {
  height: 75vh;
}

.title{
  font-weight: bold;
  font-size: larger;
  color: black;
}
</style>
